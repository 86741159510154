import React from "react"
import LayoutNew from "../components/LayoutNew"
import { Helmet } from "react-helmet"

export default function ServiceInterruptions() {
  return (
    <LayoutNew>
      <Helmet>
        {/* eslint-disable-next-line jsx-a11y/accessible-emoji */}
        <title>Service Interruptions</title>
      </Helmet>
      <section className="px-4 py-8 mx-auto font-light prose prose-lg">
        <h1>Service Interruptions</h1>

        <h2>June 15th 2024</h2>
        <p class="font-bold">8:10AM</p>
        <p>
            Good morning everyone, we're happy to report that service should now be fully restored. If you are still experiencing issues with your connection, please try power cycling your modem to see if that addresses your issue. If that doesn't work, our team is standing by to help so please reach out.
            <br/><br/>
            Thanks again for your understanding while this fibre break was repaired!
        </p>
    
        <h2>June 14th 2024</h2>
        <p class="font-bold">7:25PM</p>
        <p>
            We're starting to see internet traffic and speeds pick back up, while we're not at 100% you should see an increasing improvement in service over the next few hours. We'll post again when we confirm we're back to 100%.
            <br/><br/>
            Thank you again for your patience as crews addressed this repair.
        </p>
    
        <h2>June 14th 2024</h2>
        <p class="font-bold">6:05PM</p>
        <p>
            Our teams remain on-site at the Hwy 103 and St Margarets Bay Road, diligently working on the fiber break caused by recent construction activities. We understand the inconvenience this has caused and are committed to restoring service as quickly as possible. We appreciate your continued patience and support during this time. 
            <br/><br/>
            Please stay tuned to our social media channels and our website for further updates. 
            
            <br/><br/> Your connection is our priority, and we are working tirelessly to resolve this issue.
        </p>
        <h2>June 14th 2024</h2>
        <p className="font-bold">10:52AM</p>
        <p>
          I wanted to share this update with you. As of 10:45am we received an update indicating that the fibre break located at the Hwy 103 and St Margarets Bay Road due to construction is significantly impacting service province-wide for all providers. While crews are working to repair the issue, this will likely be an all day restoration.<br/><br/> Further updates will be provided on our socials and on our website located at https://www.purplecowinternet.com/service-interruptions.
          <br/><br/>Thank you for your patience, we're working hard to get you back online!<br/><br/>
          Bradley Farquhar<br/>
          CEO
        </p>

        <h2>June 14th 2024</h2>
        <p className="font-bold">9:15AM</p>
        <p>
          Potential Service Interruption
          I wanted to get an email out to you asap to keep you in the loop. There has been a fiber break near Bayers
          Lake Industrial Park affecting multiple internet providers across Nova Scotia. Crews are working right now on
          repairs. We are waiting on an estimated time of restoration.< br/>< br/>

          We will continue to provide updates as they become available. Thank you for your patience, we're working hard
          to get you back online! < br/>< br/>

          Bradley Farquhar< br/>
          CEO
        </p>
      </section>
    </LayoutNew>
  )
}